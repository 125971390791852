/* 
File name: index.css
Author: Alex Taylor
Website Name: Alex's mini portfolio website using React.
File Description: CSS for the entire app.
*/

html{
    margin-bottom: 65px;
    scroll-behavior: smooth;
}

/**********/
/* Global */
/**********/
h1{
    color: orangered;
    text-decoration: underline;
    text-shadow: 2px 2px #fff;
    letter-spacing: 3px;
    font-family: 'Righteous', sans-serif;
    font-weight: 400;
}
h2,h3,h4,h5,h6{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}
h5{font-size: 15px;}
p,a.li{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}
.masthead{
    display: flex;
    justify-content: center;
    margin-bottom: 2%;
    padding-top: 20px;
}
.masthead > div{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #301934e9;
    border-radius: 20px;
    width: 50%;
    height: 200px; 
}

/********/
/* Body */
/********/
body{
    background: url(../public/Assets/City-Back-Drop.jpg) no-repeat center center fixed;
}

/********/
/* Home */
/********/
#home > .container{
    min-height: 100vh;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    column-gap: 4rem;
}
#home > .container > .card{
    background-color: #301934e9;
}
#home > .container > .card > .home-text{
    margin: 5%;
    color: #fff;
}
#home > .container > .card > .home-text > h1{
    text-shadow: none;
    text-decoration: none;
    color: #fff;
    margin: 10px 0px 25px;
    line-height: 1;
    letter-spacing: 0px;
}
#home > .container > .card > .home-text > h5{
    margin-bottom: 23px;
    font-size: 19px;
    font-weight: 500;
}
#home > .container > .card > .home-text > h5 > span{
    color: orangered;
}
#home > .container > .card > .home-text > h3{
    color: orangered;
    font-size: 20px;
    font-weight: 500;
}
#home > .container > .card > .home-text > p{
    line-height: 28px;
    margin-bottom: 20px;
}
#home > .container > .card > .home-text > .social > a{
    width: 35px;
    height: 35px;
    text-decoration: none;
    display: inline-flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: black;
    font-size: 17px;
    color: #fff;
    margin-right: 22px;
    margin-bottom: 30px;
}
#home > .container > .card > .home-text > .social > a:hover{
    transform: scale(1.5);
    background-color: orangered;
    transition: .5s;
}
#home > .container > .card > .home-text > .btn{
    display: inline-block;
    color: #fff;
    background-color: orangered;
    padding: 10px 40px;
    margin-right: 20px;
    border-radius: 20px;
    transition: ease .40s;
}
#home > .container > .card > .home-text > .btn:hover{
    transform: scale(1.1);
}

#home > .container > section:nth-child(2) > img{
    height: 500px;
    width: 500px;
    border-radius: 20px;
}

/*********/
/* About */
/*********/
#about > .container{
    display: flex;
}
#about > .container > .accordion{
    width: 50%;
}
#about > .container > .accordion > .accordion-item{
    margin-bottom: 2%;
    background-color: #301934e9;
    color: #fff;
}
#about > .container > section:nth-child(2){
    padding-left: 10%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
}
#about > .container > section:nth-child(2) > img{
    border: solid 5px black;
    border-radius: 50%;
    box-shadow: 5px 10px orangered;
    height: 150px;
    width: 150px;
}
#about > .container > section:nth-child(2) > img:nth-child(even){
    grid-column-start: 2;
    grid-row-start: 2;
}
#about > .container > section:nth-child(2) > img:nth-child(3){
    grid-column-start: 1;
    grid-row-start: 3;
}

/************/
/* Projects */
/************/
#projectsPage > .card-group > .card{
    margin: 1%;
}

/************/
/* Services */
/************/
#servicesPage > .container{
    padding: 0%;
}
#servicesPage > .container > .row{
    display: flex;
    justify-content: space-evenly;
}
#servicesPage > .container > .row > figure{
    text-align: center;
    padding: 0%;
}
#servicesPage > .container > .row > figure > img{
    height: 200px;
    width: 300px;
    border-radius: 20px;
}
#servicesPage > .container > .row > figure > div{
    background-color: #301934e9;
    color: #fff;
    margin: 2%;
    border: 1px solid #fff;
    border-radius: 20px;
    box-shadow: 5px 5px orangered;
}
#servicesPage > .container > .row > figure > div > p{
    padding: 10px;
    height: 80px;
}

/***********/
/* Contact */
/***********/
#contact > .container > form{
    background-color: #301934e9;
    color: #fff;
    padding: 20px;
    border-radius: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
    'fname lname'
    'number email'
    'textarea textarea'
    'submit reset';
}
#contact > .container > form > div > input{
    width: 50%;
}
#contact > .container > form > button{
    height: 50px;
    width: 150px;
}

/* Grid area */
#contact > .container > form > div:nth-child(1){
    grid-area: fname;
}
#contact > .container > form > div:nth-child(2){
    grid-area: lname;
}
#contact > .container > form > div:nth-child(3){
    grid-area: number;
}
#contact > .container > form > div:nth-child(4){
    grid-area: email;
}
#contact > .container > form > div:nth-child(5){
    grid-area: textarea;
}
#contact > .container > form > button:nth-child(1){
    grid-area: submit;
}
#contact > .container > form > button:nth-child(2){
    grid-area: reset;
}
/* 
File name: App.css
Author: Alex Taylor
Website Name: Alex's mini portfolio website using React.
File Description: CSS for App Component.
*/
/**********/
/* Header */
/**********/
header{
    height: 150px;
}
.navbar{
    background-color: #301934;
}
.navbar-brand{
    height: 120px;
}
.navbar-brand > img{
    height: 100%;
}

/**********/
/* Footer */
/**********/
h5{font-size: 15px;}